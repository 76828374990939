// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../api/Api.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as CityStateCountry from "../../../../models/CityStateCountry.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardGeneralInformation from "../_components/ProjectWizardGeneralInformation/ProjectWizardGeneralInformation.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration from "../create-colocation-rfp-project-configuration/CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.res.js";

function title(colocationConfigurations) {
  var __x = Belt_Array.map(colocationConfigurations, (function (config) {
          return CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Input.title(config);
        }));
  return Js_array.joinWith(", ", __x);
}

function description(colocationConfigurations, selectedCities) {
  return "The proposed colocation project has a projected service start date of " + ((function (__x) {
                return Js_array.joinWith(", ", __x);
              })(Belt_Array.map(colocationConfigurations, (function (config) {
                      var date = config.startDate;
                      if (date !== undefined) {
                        return $$Date.Naive.format(Caml_option.valFromOption(date), "dd MMM yyyy");
                      } else {
                        return "";
                      }
                    }))) + (" with a contract term of " + ((function (__x) {
                    return Js_array.joinWith(", ", __x);
                  })(Belt_Array.map(colocationConfigurations, (function (config) {
                          var length = config.contractLength;
                          if (length !== undefined) {
                            return String(length / 12 | 0);
                          } else {
                            return "";
                          }
                        }))) + (" years. We need service in " + (function (__x) {
                      return Js_array.joinWith("; ", __x);
                    })(Belt_Array.map(selectedCities, CityStateCountry.toLabel))))));
}

var ProjectInfo = {
  title: title,
  description: description
};

function createProject(extra, extra$1, extra$2, extra$3, extra$4, extra$5, extra$6, extra$7, extra$8, extra$9) {
  return Api.createNewProject({
              TAG: "Colocation",
              _0: "Rfp"
            }, extra, extra$1, extra$2, extra$3, extra$4, extra$5, extra$6, extra$7, extra$8, extra$9);
}

function saveDraftProjectAndContinueLater(extra) {
  return Api.saveColocationDraftProjectAndContinueLater("Colocation", {
              TAG: "Colocation",
              _0: "Rfp"
            }, extra);
}

var Api$1 = {
  saveDraft: Api.saveProjectGeneralInfoDraft,
  createProject: createProject,
  updateProject: Api.updateProject,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater
};

function CreateColocationRfpProjectGeneralInformation(props) {
  var description$1 = props.description;
  var selectedCities = props.selectedCities;
  var colocationConfigurations = props.colocationConfigurations;
  var title$1 = props.title;
  var projectId = props.projectId;
  var title$2 = React.useMemo((function () {
          if (title$1 !== undefined && title$1 !== "") {
            return title$1;
          } else {
            return title(colocationConfigurations);
          }
        }), [
        description$1,
        colocationConfigurations
      ]);
  var description$2 = React.useMemo((function () {
          if (description$1 !== undefined && description$1 !== "") {
            return description$1;
          } else {
            return description(colocationConfigurations, selectedCities);
          }
        }), [
        description$1,
        colocationConfigurations,
        selectedCities
      ]);
  var navigationBackButtonLocation = React.useMemo((function () {
          if (projectId !== undefined) {
            return Routes_Project.Colocation.$$Location.update(Caml_option.valFromOption(projectId));
          } else {
            return Routes_Project.Colocation.$$Location.select;
          }
        }), [projectId]);
  return JsxRuntime.jsx(ProjectWizardGeneralInformation.make, {
              api: Api$1,
              userLoginStatus: props.userLoginStatus,
              userRole: props.userRole,
              projectId: projectId,
              title: title$2,
              description: description$2,
              alreadyUploadedDocuments: props.alreadyUploadedDocuments,
              businessName: props.businessName,
              businessContactName: props.businessContactName,
              businessEmail: props.businessEmail,
              businessPhone: props.businessPhone,
              headquartersAddress: props.headquartersAddress,
              contacts: props.contacts,
              isAgent: props.isAgent,
              navigationTotalSteps: 3,
              navigationBackButtonLocation: navigationBackButtonLocation,
              mobile: props.mobile,
              status: props.status
            });
}

var make = CreateColocationRfpProjectGeneralInformation;

export {
  ProjectInfo ,
  Api$1 as Api,
  make ,
}
/* Api Not a pure module */
